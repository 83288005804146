import type { GetStaticProps } from 'next'
import { ErrorPage } from '@shopper/app/components/ErrorPage'

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      statusCode: 404,
    },
  }
}

export default ErrorPage
